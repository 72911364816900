<template>
  <b-modal
      id="add-customer-relations-modal"
      title="Ajouter des relations clients"
      size="xl"
      @hidden="clearInput"
  >
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
              autofocus
              v-model="searchedTerm"
              placeholder="Recherche"
              @keyup.enter="searchCustomersByTerm"
          />
          <b-input-group-append>
            <b-button
                variant="outline-primary"
                @click="searchCustomersByTerm"
            >
              <font-awesome-icon icon="search"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col v-if="customers.length > 0">
        <b-pagination
            class="float-right"
            v-model="currentPage"
            :total-rows="total"
            :per-page="pageSize"
            @change="loadCustomers"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2 justify-content-center">
      <b-col cols="1" v-if="loading">
        <AppPulsingIcon/>
      </b-col>
      <b-col v-else>
        <b-table
            hover
            striped
            borderless
            head-variant="light"
            :items="customers"
            :fields="fields"
            v-if="customers.length > 0"
        >
          <template #cell(search)="customer">
            {{ customer.item.lastName.toUpperCase() }}
            {{ customer.item.firstName }}
            {{ customer.item.email }}
          </template>

          <template #cell(edit)="customer">
            <div class="row justify-content-end mr-0">
              <AddButton class="no-border" @add="$emit('add-customer-relation', customer.item)"/>
            </div>
          </template>
        </b-table>
        <span v-else>Aucun client ne correspond à votre recherche</span>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button variant="secondary" @click="cancel()">Fermer</b-button>
    </template>
  </b-modal>
</template>

<script>
import AppPulsingIcon from "@/components/AppPulsingIcon";
import AddButton from "@/components/buttons/AddButton";

import {mapState} from "vuex";

export default {
  data() {
    return {
      loading: false,
      searchedTerm: null,
      currentPage: 0,
      fields: [
        {key: "lastName", label: "Nom"},
        {key: "firstName", label: "Prénom"},
        {key: "email", label: "Email"},
        {key: "edit", label: ""},
      ],
    };
  },
  computed: {
    ...mapState({
      customers: (state) => state.customers.all,
      total: (state) => state.customers.total,
      pageSize: (state) => state.customers.pageSize,
    }),
  },
  async created() {
    await this.loadCustomers();
  },
  methods: {
    async loadCustomers(page = 1) {
      this.loading = true;
      try {
        await this.$store.dispatch("customers/fetchCustomers", {
          page,
          search: this.searchedTerm ?? null,
        });
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la récupération des clients",
          text: e.message,
        });
        return;
      }
      this.loading = false;
    },
    searchCustomersByTerm() {
      this.currentPage = 1;
      this.loadCustomers(this.currentPage);
    },
    clearInput() {
      this.searchedTerm = null;
    },
  },
  components: {
    AddButton,
    AppPulsingIcon,
  },
};
</script>

