<template>
  <div>
    <RemovableBadge
      class="m-1"
      v-for="relation in relations"
      :key="relation.uid"
      @click="$emit('click', relation)"
      @remove="$emit('remove', relation)"
    >
      {{ relation.user.firstName }} {{ relation.user.lastName }}
    </RemovableBadge>
  </div>
</template>

<script>
import RemovableBadge from "@/components/RemovableBadge";

export default {
  props: {
    relations: {
      type: Array,
      required: true,
    },
  },
  components: {
    RemovableBadge,
  },
};
</script>
