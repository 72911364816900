export function randomString(length) {
    let result = Array(length);
    const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const CHARS_LENGTH = CHARS.length;
    for (let i = 0; i < length; i++) {
        result.push(CHARS.charAt(Math.floor(Math.random() * CHARS_LENGTH)));
    }
    return result.join('');
}

export const permissionToString = (permission) => permission.scope + "." + permission.resource + "." + permission.operation;
