<template>
  <div>
    <b-form @submit.prevent="updateDomain">
      <b-form-group label="Nom" label-for="name-input">
        <b-form-input
          id="name-input"
          v-model="$v.d.name.$model"
          type="text"
          required
          placeholder="Renseignez un nom"
          :state="$v.d.name.$dirty ? !$v.d.name.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.d.name.$invalid">
          <span v-if="!$v.d.name.required">Vous devez renseigner ce champ</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Clé d'API" label-for="api-key-input">
        <b-form-input
          id="api-key-input"
          v-model="$v.d.apiKey.$model"
          type="text"
          required
          placeholder="Renseignez une clé d'API"
          :state="$v.d.apiKey.$dirty ? !$v.d.apiKey.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.d.apiKey.$invalid">
          <span v-if="!$v.d.apiKey.required"
            >Vous devez renseigner ce champ</span
          >
          <span v-else-if="!$v.d.apiKey.alphaNum"
            >La clé d'API doit être une chaine de caractères
            alphanumérique</span
          >
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Date de génération"
        label-for="generation-date-input"
      >
        <b-form-datepicker
          id="generation-date-input"
          v-model="$v.d.generationDate.$model"
          :state="
            $v.d.generationDate.$dirty ? !$v.d.generationDate.$invalid : null
          "
        />
        <b-form-invalid-feedback :state="!$v.d.generationDate.$invalid">
          <span v-if="!$v.d.generationDate.required"
            >Vous devez renseigner ce champ</span
          >
          <span v-else-if="!$v.d.generationDate.dateISO8601"
            >Vous devez renseigner une date valide</span
          >
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Type" label-for="type-input">
        <b-form-select
          id="type-input"
          v-model="$v.d.type.$model"
          :options="types.slice(0, 3)"
          :state="$v.d.type.$dirty ? !$v.d.type.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.d.type.$invalid">
          <span v-if="!$v.d.type.validType">Type de domaine invalide</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="$v.d.externalAuth.$model" switch>
          Authentification externe
        </b-form-checkbox>
      </b-form-group>
      <b-form-group v-if="!d.externalAuth">
        <template slot="label">
          Relations utilisateurs
          <font-awesome-icon
            icon="plus-circle"
            :style="{ color: 'green' }"
            v-b-modal.add-user-relations-modal
          />
        </template>
        <DomainUserRelationList
          :relations="r.users"
          @click="openUserRelation"
          @remove="removeUserRelation"
        />
      </b-form-group>
      <b-form-group v-if="!d.externalAuth">
        <template slot="label">
          Relations clients
          <font-awesome-icon
            icon="plus-circle"
            :style="{ color: 'green' }"
            v-b-modal.add-customer-relations-modal
          />
        </template>
        <DomainCustomerRelationList
          :relations="r.customers"
          @click="openCustomerRelation"
          @remove="removeCustomerRelation"
        />
      </b-form-group>
      <b-button type="submit" variant="primary" :disabled="$v.$invalid">
        Envoyer
      </b-button>
    </b-form>
    <AddUserRelationsModal
      v-if="!d.externalAuth"
      @add-user-relation="addUserRelation"
    />
    <AddCustomerRelationsModal
      v-if="!d.externalAuth"
      @add-customer-relation="addCustomerRelation"
    />
  </div>
</template>

<script>
import { randomString } from "@/functions";
import { boolean, dateISO8601 } from "@/validators";
import { alphaNum, required } from "vuelidate/lib/validators";

import DomainUserRelationList from "./DomainUserRelationList";
import DomainCustomerRelationList from "./DomainCustomerRelationList";

import AddUserRelationsModal from "./modals/AddUserRelationsModal";
import AddCustomerRelationsModal from "./modals/AddCustomerRelationsModal";

import { DateTime } from "luxon";

const DOMAIN_TYPES = [
  { value: "jt", text: "Jalis Touch" },
  { value: "ej", text: "EJalis" },
  { value: "api", text: "API" },
  { value: "", text: "" },
];

export default {
  props: {
    domain: {
      type: Object,
      required: true,
    },
    relations: {
      type: Object,
      required: true,
    },
  },
  data() {
    const d = Object.assign({}, this.domain);
    d.generationDate = DateTime.fromISO(d.generationDate).toFormat(
      "yyyy-LL-dd"
    ); // ensure date format
    return {
      d,
      r: JSON.parse(JSON.stringify(this.relations)), // deep copy state to avoid mutation
      types: DOMAIN_TYPES,
    };
  },
  validations: {
    d: {
      name: {
        required,
      },
      apiKey: {
        required,
        alphaNum,
      },
      generationDate: {
        required,
        dateISO8601,
      },
      externalAuth: {
        boolean,
      },
      type: {
        validType: (value) => DOMAIN_TYPES.find((item) => item.value === value),
      },
    },
  },
  methods: {
    async updateDomain() {
      const addedUserRelations = this.r.users.filter(
        (relation) =>
          !this.relations.users.some(
            (userRelation) => relation.user.uid === userRelation.user.uid
          )
      );
      const removedUserRelations = this.relations.users.filter(
        (userRelation) =>
          !this.r.users.some(
            (relation) => relation.user.uid === userRelation.user.uid
          )
      );
      const addedCustomerRelations = this.r.customers.filter(
        (relation) =>
          !this.relations.customers.some(
            (customerRelation) =>
              relation.customer.uid === customerRelation.customer.uid
          )
      );
      const removedCustomerRelations = this.relations.customers.filter(
        (customerRelation) =>
          !this.r.customers.some(
            (relation) =>
              relation.customer.uid === customerRelation.customer.uid
          )
      );

      try {
        await Promise.all([
          this.$store.dispatch("domains/updateDomain", this.d),
          this.$store.dispatch("domains/relations/addUserRelations", {
            domain: this.domain,
            users: addedUserRelations.map((relation) => relation.user),
          }),
          this.$store.dispatch("domains/relations/addCustomerRelations", {
            domain: this.domain,
            customers: addedCustomerRelations.map(
              (relation) => relation.customer
            ),
          }),
          this.$store.dispatch(
            "domains/relations/removeUserRelations",
            removedUserRelations
          ),
          this.$store.dispatch(
            "domains/relations/removeCustomerRelations",
            removedCustomerRelations
          ),
        ]);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la mise à jour du domaine",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Domaine modifié",
      });
    },
    addUserRelation(user) {
      if (this.userRelationAlreadyExist(user)) {
        this.toastRelationAlreadyExist();
        return;
      }
      this.r.users.push({
        uid: null,
        user,
      });
      this.toastRelationAdded();
    },
    openUserRelation(relation) {
      this.$router.push({ path: `/users/${relation.user.uid}` });
    },
    removeUserRelation(relation) {
      this.r.users = this.r.users.filter((item) => item.uid !== relation.uid);
    },
    addCustomerRelation(customer) {
      if (this.customerRelationAlreadyExist(customer)) {
        this.toastRelationAlreadyExist();
        return;
      }
      this.r.customers.push({
        uid: randomString(32),
        customer,
      });
      this.toastRelationAdded();
    },
    openCustomerRelation(relation) {
      this.$router.push({ path: `/customers/${relation.customer.uid}` });
    },
    removeCustomerRelation(relation) {
      this.r.customers = this.r.customers.filter(
        (item) => item.uid !== relation.uid
      );
    },
    userRelationAlreadyExist(user) {
      return this.r.users.some((relation) => relation.user.uid === user.uid);
    },
    customerRelationAlreadyExist(customer) {
      return this.r.customers.some(
        (relation) => relation.customer.uid === customer.uid
      );
    },
    toastRelationAlreadyExist() {
      this.$toast.warning("Cette relation existe déjà");
    },
    toastRelationAdded() {
      this.$toast.success("Relation ajoutée");
    },
  },
  components: {
    DomainUserRelationList,
    DomainCustomerRelationList,
    AddUserRelationsModal,
    AddCustomerRelationsModal,
  },
};
</script>
