<template>
  <div>
    <UpdateDomainForm v-if="ready" :domain="domain" :relations="relations"/>
    <b-row v-else class="justify-content-center">
      <b-col cols="1">
        <AppPulsingIcon/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex";

import UpdateDomainForm from "@/components/domains/UpdateDomainForm";
import AppPulsingIcon from "@/components/AppPulsingIcon";

export default {
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapState({
      domain: (state) => state.domains.domain,
      relations: (state) => state.domains.relations.all,
    }),
  },
  components: {
    UpdateDomainForm,
    AppPulsingIcon,
  },
  async created() {
    try {
      await this.$store.dispatch("domains/fetchDomain", this.uid);
    } catch (e) {
      await this.$swal.fire({
        icon: "error",
        title: "Une erreur est survenue lors de la récupération du domaine",
        text: e.message,
      });
      await this.$router.push({name: 'domains'})
      return;
    }
    this.ready = true;
  },
};
</script>

